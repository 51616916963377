import React from "react";
import { graphql, Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import Testimonials from "../components/Repeating/Testimonials";
import Resources from "../components/Repeating/Resources";
import CallToAction from "../components/Repeating/CTA";
import ButtonSolid from "../components/Button/ButtonSolid";

import line from "../images/12.0 Our Process/line.svg";

const Page = ({ data }) => {
  return (
    <Layout>
      <SearchEngineOptimization
        title="Our Process | Home Loans | Accel Mortgage"
        description="Our process is personalized for each person to make sure they get the help they need to find the mortgage and home loan for them. Learn more here."
        // openGraphImage={data.openGraphImage.publicURL}
        // twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
      />

      <section className="mb-20 overflow-x-hidden md:mb-32">
        <div className="container">
          <div className="grid items-center gap-y-10 md:grid-cols-2 md:gap-x-10 lg:gap-x-20">
            <div className="order-2 md:order-1">
              <h1>What Does the Loan Process Look Like?</h1>
              <p>
                At Accel Mortgage, we make the home loan process easy. You get
                the tools you need, expert analysis to make smart decisions,
                plus guidance through every step. And before you know it, you’ll
                have keys in hand to your new home in California!
              </p>
              <div className="relative inline-grid items-center gap-y-4 md:flex md:gap-y-0 md:space-x-4">
                <ButtonSolid href="/request-rates/" text="Get a Rate Quote" />
                <ButtonSolid
                  href="https://markdarling.floify.com/apply-now"
                  text="Get Pre-Approved"
                  outboundLink={true}
                  altStyle={2}
                />
              </div>
            </div>
            <div className="order-1 md:order-2 md:w-[50vw]">
              <StaticImage
                src="../images/12.0 Our Process/1.0 Hero.png"
                loading="eager"
              />
            </div>
          </div>
        </div>
      </section>

      <section className="mb-20 md:mb-32">
        <div className="container">
          <header className="mx-auto mb-16 max-w-[680px] text-center md:mb-20">
            <h2>You Could Be Fully Funded In 30 Days</h2>
            <p className="mx-auto mb-0 max-w-[516px]">
              When you work with the Accel team, you can get your home loan
              usually in a month or less.
            </p>
          </header>

          <div className="relative mx-auto max-w-[1040px]">
            <img
              src={line}
              className="absolute inset-x-0 top-40 mx-auto hidden md:block"
            />
            <div className="mb-16 grid items-center gap-y-10 md:mb-20 md:grid-cols-2 md:gap-x-10 lg:gap-x-20">
              <div>
                <StaticImage
                  src="../images/12.0 Our Process/2.0 step1.jpg"
                  loading="lazy"
                  width={440}
                  className="z-0 rounded-6xl"
                />
              </div>

              <div className="flex space-x-5">
                <div className="number-text-stroke">1</div>
                <div>
                  <h3>Get Pre-Approved</h3>
                  <p className="mb-0">
                    Get a FREE pre-approval letter, so an offer you make on a
                    home is taken seriously by a seller.
                  </p>
                </div>
              </div>
            </div>

            <div className="mb-16 grid items-center gap-y-10 md:mb-20 md:grid-cols-2 md:gap-x-10 lg:gap-x-20">
              <div className="md:order-2 md:text-right">
                <StaticImage
                  src="../images/12.0 Our Process/3.0 step2.jpg"
                  loading="lazy"
                  width={440}
                  className="z-0 rounded-6xl"
                />
              </div>

              <div className="flex space-x-5">
                <div className="number-text-stroke">2</div>
                <div>
                  <h3>Review Your Loan Options</h3>
                  <p className="mb-0">
                    We’ll look at your criteria plus financial picture and
                    provide you with the best options.
                  </p>
                </div>
              </div>
            </div>

            <div className="mb-16 grid items-center gap-y-10 md:mb-20 md:grid-cols-2 md:gap-x-10 lg:gap-x-20">
              <div>
                <StaticImage
                  src="../images/12.0 Our Process/4.0 step3.jpg"
                  loading="lazy"
                  width={440}
                  className="z-0 rounded-6xl"
                />
              </div>

              <div className="flex space-x-5">
                <div className="number-text-stroke">3</div>
                <div>
                  <h3>Compare Interest Rates & Terms</h3>
                  <p className="mb-0">
                    It pays to compare. Weigh each loan option to determine
                    which loan is right for you.
                  </p>
                </div>
              </div>
            </div>

            <div className="grid items-center gap-y-10 md:grid-cols-2 md:gap-x-10 lg:gap-x-20">
              <div className="md:order-2 md:text-right">
                <StaticImage
                  src="../images/12.0 Our Process/5.0 step4.jpg"
                  loading="lazy"
                  width={440}
                  className="z-0 rounded-6xl"
                />
              </div>

              <div>
                <div className="flex space-x-5">
                  <div className="number-text-stroke">4</div>
                  <div>
                    <h3>Choose A Loan That Fits Your Needs</h3>
                    <p>
                      Make an informed decision on the right home loan using the
                      tools and information we’ve provided you.
                    </p>
                  </div>
                </div>

                <div className="relative inline-grid items-center gap-y-4 md:flex md:gap-y-0 md:space-x-4">
                  <ButtonSolid href="/request-rates/" text="Get a Rate Quote" />
                  <ButtonSolid
                    href="https://markdarling.floify.com/apply-now"
                    text="Get Pre-Approved"
                    outboundLink={true}
                    altStyle={2}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Testimonials />
      <Resources />
      <CallToAction />
    </Layout>
  );
};

export const data = graphql`
  {
    openGraphImage: file(
      relativePath: { eq: "Open Graph/Facebook/Homepage.jpg" }
    ) {
      publicURL
    }
    twitterOpenGraphImage: file(
      relativePath: { eq: "Open Graph/Twitter/Homepage.jpg" }
    ) {
      publicURL
    }
  }
`;

export default Page;
